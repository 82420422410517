import tw from "twin.macro"
import { css } from "@emotion/react"

const customGlobalStyles = css`
  html {
    font-size: 16px;
    /* for testing purposes */

    /* system font stack  https://css-tricks.com/snippets/css/system-font-stack/  */
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */

    /* Project Fallback Fonts */
    /* font-family: Helvetica Neue,Arial,sans-serif; */

    ${tw`font-sans font-book text-black`}
    font-display: swap;

    scroll-behavior: smooth;

    body {
      /* Section elements with id's (i.e. template sections with anchors) */
      section[id] {
        scroll-margin-top: 70px;
      }
      /* Removes annoying blue box shadow inset that Chrome forces on input elements when auto-filling */
      /* This is the single best solution because it allows for a transparent background, all other solutions set a solid color */
      input:-webkit-autofill {
        -webkit-background-clip: text;
      }
      textarea:-webkit-autofill {
        -webkit-background-clip: text;
      }

      /* REMOVES BLUE HIGHLIGHT FROM BUTTON TAPS */
      button,
      a {
        -webkit-tap-highlight-color: transparent;
        ${tw`focus-visible:outline-gray`}
      }
    }
  }

  [id^="ae-launcher-container"] {
    display: none;
  }

  .alice-carousel,
  .alice-carousel__wrapper,
  .alice-carousel__stage,
  .alice-carousel__stage-item {
    ${tw`shadow-none`}
  }
  // target #favorites-carousel with child .alice-carousel__wrapper
  #favorites-carousel .alice-carousel__wrapper {
    padding-bottom: 500px;
    margin-bottom: -500px;
  }
  .alice-carousel__stage-item.__target .coupon {
    ${tw`border-toyotaRed shadow-4`}
  }

  @media (min-width: 860px) {
    .modelListItems .alice-carousel__stage-item,
    .modelListItems .alice-carousel__stage-item.__active,
    .modelListItems .alice-carousel__stage-item.__target {
      max-width: 320px !important;
    }
  }

  .accFilter .alice-carousel__wrapper {
    padding-right: 50px !important;
    margin-right: -50px !important;
    padding-top: 5px !important;
  }
 @media (min-width: 640px) { 
  .accFilter .alice-carousel__stage {
    width: 600px !important;
  }}
  @media (max-width: 640px) {
    .accFilter .alice-carousel__wrapper {
      padding-right: 30px !important;
      margin-right: -30px !important;
    }
  }
  @media (max-width: 767px) {
    .accFilter .alice-carousel__stage-item, 
    .accFilter .alice-carousel__stage {
      width: 320px !important;
  }
  @media (max-width: 640px) {
    .accFilter .alice-carousel {
      width: calc(100vw - 45px) !important;
    }
  }
  .accCardDescription > p {
    padding-bottom: 0.75rem !important;
  }
  .accCardDescription > ul {
    list-style-type: disc !important;
    margin-left: 1.25rem !important;
  }
  .accCardDescription > li {
    padding-bottom: 0.75rem !important;
  }
`

export default customGlobalStyles
